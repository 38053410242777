:root {
  --sub-text-color: #6b6c6d;
  --google-blue-button: #1a73e8;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.row {
  margin: 1vh 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1vw;
}

.map-picker-select {
  background-color: var(--google-blue-button);
  border-radius: 99px;
  color: white;
  border: 0;
  display: flex;
  gap: 1vw;
  align-items: center;
  justify-content: center;
  padding: 1vh 1.5vw;
}

p {
  margin: 0;
}
