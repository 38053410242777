.mediaForm .media {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    width: 100%;
    overflow: auto;
}

.mediaForm .media {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    width: 100%;
    overflow: auto;
    gap: 16px;
}

.mediaForm .mediaItem {
    position: relative;
}

.mediaForm .mediaItem img, .mediaForm .mediaItem video {
    height: 300px;
    position: relative;
}

.mediaForm .mediaItem .remove-button {
    background-color: #f44336;
    border-radius: 6px;
    padding: 6px;
    color: white;
    border: none;
    cursor: pointer;
    position: absolute;
    top: 10px;
    left: 10px;
}

.mediaForm .add-button {
    background-color: rgba(0, 0, 0, 0.75);
    color: white;
    border-radius: 6px;
    padding-left: 150px;
    padding-right: 150px;
    border: none;
    cursor: pointer;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: xx-large;
    font-weight: bolder;
}

.mediaForm .mediaItemNew {
    border: 2px dashed #4CAF50;
}