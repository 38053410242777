html,
body,
#root {
  margin: 0;
  height: 100vh;
  overflow: hidden;
}

.checked {
  color: #00bcd4;
}

.bar {
  animation: bar 1.5s ease;
  -webkit-animation: bar 1.5s ease;
}

@keyframes bar {
  0% {
    top: 100%;
  }
  100% {
    top: 50%;
  }
}
