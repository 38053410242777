/*////////////////////  GENERAL  /////////////////////*/
.drawer{
    overflow: hidden;
}
.map-editor{
    width: 100%;
    margin: 40px 0;
}

.editor-tabs{
    position: relative;
    background-color: #EFF1F3;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 2px;
    border-radius: 12.5px;
    width: fit-content;
    font-size: 10px;
    font-weight: 500;
    margin: auto;
}

.editor-tab{
    width: fit-content;
    justify-content: center;
    background-color: #EFF1F3;
    color: #CFD1D5;
    margin: 1px 5px;
    padding: 5px 10px;
    border-radius: 12.5px;
    font-size: 13px;
}

.editor-tab:hover{
    cursor: pointer;
}

.editor-tab-clicked{
    background-color: #FFF;
    color: #51515B;
}

.style-editor-form{
    height: 100%;
}


/*////////////////////  INFO  /////////////////////*/
.info{
    margin: 10px;
    height: calc(100vh - 100px);
    width: calc(100%);
    overflow: auto;
}  

.info-editor-form textarea{
    margin-left: 5px;
    padding: 5px 10px;
}
.input-text{
    width: 100%;
}
.input-text textarea{
    height: 40px;
    border: solid 1px #51515B;
    border-radius: 5px;
}.input-text textarea:focus {
    outline: none;
}.input-text textarea:focus:hover {
    outline: none;
}

.info .input-element{
  padding: 20px 20px;
  display: grid;
  grid-template-columns: 50% 50%;
  gap: 20px;
}

.info .input-element1{
  padding: 10px 20px;
  display: grid;
  grid-template-columns: 100%;
}

/*////////////////////  STYLE  /////////////////////*/

.style{
    margin: 10px;
    width: calc(100%);
    height: calc(100vh - 100px);
    overflow-y: auto;
    overflow-x: auto;
}  

.style-editor-form textarea{
    margin-left: 5px;
    padding-left: 5px;

}

.style .input-element{
  padding: 20px 20px;
  display: grid;
  grid-template-columns: 50% 50%;
  gap: 20px;
}

.style .input-element1{
  padding: 20px 20px;
  display: grid;
  grid-template-columns: 100%;
}

/*//////number Input //////*/
.input-counter{
    margin: auto;
}
.input-counter input{
    width: 40px;
}
.number-input{
    display: flex;
}
.number-input-wrapper{
    /* width: 90%; */
    display: flex;
    align-items: center;
}
.number-input-wrapper label{
  margin: auto 0;
}
/* .number-XYinput-wrapper{
  margin: 30px 0 0 0;
} */

.number-input-container{
    margin-left: 5px;
    padding: 5px 10px;
    border: 1px solid #51515B;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    /* align-items: center; */
}

.input-counter{
    width: fit-content;
}
.input-counter input{
    height: 20px;
    border: none;
}.input-counter input:focus {
    outline: none;
}.input-counter input:focus:hover {
    outline: none;
}

.counter-containers{
    width: fit-content;
    height: fit-content;
}

.Count-up{
    font-size: 9px;
    cursor: pointer;
}
.Count-down{
    font-size: 9px;
    cursor: pointer;
}


/*//////String Input //////*/

.input-text input{
    margin-left: 5px;
    /* border: none;
    border-bottom: #51515B solid 1px; */
    border: #51515B solid 1px;
    border-radius: 5px;
    height: 30px;
    padding: 0 10px;
    /* width: 30px; */
}

.text-input-wrapper{
    width: 100%;
    display: flex;
    flex-direction: column;
}

#color-picker{
  border-radius: 5px;
  border: none;
  background-color: #ffffff;
  cursor: pointer;
  width: 100%;
}

/*//////Dropdown Input //////*/
.input-dropdown select{
    margin-left: 5px;
    border: none;
    border-bottom: #51515B solid 1px;
    padding: 0 5px;
    /* width: 30px; */
}

.dropdown-input-wrapper{
    display: flex;
    margin: auto 0;
}
.dropdown-input-wrapper label{
  margin: auto 0;
}

.dropdown-input{
  height: fit-content;
  margin: auto 0;
}

/*//////XYInput Input //////*/
.number-input-sublabel{
    margin: auto 5px auto 3px;
    height: fit-content;
    font-weight: 600;

}


/*////// Save Button //////*/
.style-bottom-container{
    margin: 40px auto 20px;
    display: flex;
    width: 100%;
    justify-content: space-around;
    font-size: 14px;
}
.save-button{
    outline:none;
    height: 40px;
    text-align: center;
    width: 130px;
    border-radius:40px;
    background: #fff;
    border: 2px solid rgb(32, 160, 195);
    color:rgb(32, 160, 195);
    letter-spacing:1px;
    text-shadow:0;
    font-size:14px;
    font-weight:bold;
    cursor: pointer;
    transition: all 0.25s ease;
    
}
.save-button:hover{
    color:white;
    background: rgb(32, 160, 195);
}
.save-button:active{
    letter-spacing: 2px;
    letter-spacing: 2px;
}

.cancel-button{
    outline:none;
    height: 40px;
    text-align: center;
    width: 80px;
    border-radius:40px;
    background: #fff;
    border: 2px solid rgb(13, 70, 86);
    color:rgb(13, 70, 86);
    letter-spacing:1px;
    text-shadow:0;
    font-size:14px;
    font-weight:bold;
    cursor: pointer;
    transition: all 0.25s ease;
    
}
.cancel-button:hover{
    color:white;
    background: rgb(13, 70, 86);
}
.cancel-button:active{
    letter-spacing: 2px;
    letter-spacing: 2px;
}



/*////////////Selectors/////////////*/

.selects-wrapper label{
  /* width: 90%; */
  margin: 10px 0 10px;
}

.selector {
  margin-bottom: 20px;
}



/*////////////Margins/////////////*/
.margin-Container{
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 50px;
}
.margin-Container .label{
  font-weight: 600;
}

.margin-wrapper{
  width: fit-content;
  height: fit-content;
  display: flex;
  gap: 20px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.margin-wrapper .middle{
  width: fit-content;
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
}

.margin-item{
  text-align: center;
  margin: 0;
  width: 40px;
  border: solid 1px blue;
  border-radius: 5px;
  cursor: pointer;
}

/*   ALL LABEL SET TO BOLD   */
label {
  font-weight: 600;
  margin: auto 0;

} 


#color-picker{
  width: 80px;
  height: 40px;
}

.text-input-wrapper label {
  padding-bottom: 5px;
}.number-XYinput-wrapper label {
  padding-bottom: 5px;
}

/*////////Slider Input//////////*/
.slider-input-container{
  min-width: 200px;
  width: 20vw;
  margin: 0 0 0 20px;
}

.rotate-slider {
  -webkit-appearance: none;
  width: 100%;
  height: 15px;
  border-radius: 5px;  
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: .2s;
  transition: opacity .2s;
}

.rotate-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  border-radius: 50%; 
  background: #04AA6D;
  cursor: pointer;
}

.rotate-slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #04AA6D;
  cursor: pointer;
}


/*////show-name-icon-wrapper/////*/
.show-name-icon{
  background-color: #135c41;
  color: #FFF;
  font-weight: 600;
  padding: 10px;
  width: fit-content;
  margin: auto;
  border-radius: 10px;
  cursor: pointer;
}

/*////////FONTS//////////*/

/* latin */
@font-face {
  font-family: 'Aldrich';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/aldrich/v17/MCoTzAn-1s3IGyJMVacY3w.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* tamil */
@font-face {
  font-family: 'Arima Madurai';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/arimamadurai/v14/t5tmIRoeKYORG0WNMgnC3seB3SjPr6OH.woff2) format('woff2');
  unicode-range: U+0964-0965, U+0B82-0BFA, U+200C-200D, U+20B9, U+25CC;
}
/* vietnamese */
@font-face {
  font-family: 'Arima Madurai';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/arimamadurai/v14/t5tmIRoeKYORG0WNMgnC3seB3THPr6OH.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Arima Madurai';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/arimamadurai/v14/t5tmIRoeKYORG0WNMgnC3seB3TDPr6OH.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Arima Madurai';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/arimamadurai/v14/t5tmIRoeKYORG0WNMgnC3seB3T7Prw.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: 'Arvo';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/arvo/v20/tDbD2oWUg0MKqScQ7Q.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: 'Henny Penny';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/hennypenny/v17/wXKvE3UZookzsxz_kjGSfPQtvXI.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: 'Indie Flower';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/indieflower/v17/m8JVjfNVeKWVnh3QMuKkFcZVaUuH.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/librebaskerville/v14/kmKnZrc3Hgbbcjq75U4uslyuy4kn0qNXaxMICA.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Libre Baskerville';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/librebaskerville/v14/kmKnZrc3Hgbbcjq75U4uslyuy4kn0qNZaxM.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'Pirata One';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/pirataone/v22/I_urMpiDvgLdLh0fAtofhiGOrq_B.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Pirata One';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/pirataone/v22/I_urMpiDvgLdLh0fAtofhi-Org.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic */
@font-face {
  font-family: 'Poiret One';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/poiretone/v14/UqyVK80NJXN4zfRgbdfbo5pcV_cx.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* latin-ext */
@font-face {
  font-family: 'Poiret One';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/poiretone/v14/UqyVK80NJXN4zfRgbdfbo5BcV_cx.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Poiret One';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/poiretone/v14/UqyVK80NJXN4zfRgbdfbo55cVw.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'Sancreek';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/sancreek/v23/pxiHypAnsdxUm159X4D3V159EQ.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Sancreek';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/sancreek/v23/pxiHypAnsdxUm159X4D5V14.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: 'Satisfy';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/satisfy/v17/rP2Hp2yn6lkG50LoCZOIHQ.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: 'Share Tech Mono';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/sharetechmono/v15/J7aHnp1uDWRBEqV98dVQztYldFcLowEF.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: 'Smokum';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/smokum/v24/TK3iWkUbAhopmrd2GT8G.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

